var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChangePasswordMutation } from '@shared/api/auth';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import { useToastNotification } from '@shared/ui/overlay/Toast';
const usePasswordChange = () => {
    const { token } = useParams();
    const [changePasswordReq, { isLoading }] = useChangePasswordMutation();
    const { navigate, routes } = useAppNavigation();
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const changePassword = (password) => __awaiter(void 0, void 0, void 0, function* () {
        if (!token) {
            return false;
        }
        const res = yield changePasswordReq({
            recoveryToken: token,
            password,
            passwordConfirmation: password,
        });
        if (res === null || res === void 0 ? void 0 : res.error) {
            toast({
                message: t('errors.internal_server_error', { ns: 'common' }),
                variant: 'error',
                displayStyle: 'solid',
            });
            return { error: res === null || res === void 0 ? void 0 : res.error };
        }
        navigate(routes.AUTH);
        return true;
    });
    return {
        changePassword,
        isChanging: isLoading,
    };
};
export default usePasswordChange;
